.image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-popup-container {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-popup-close {
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50px;
  padding: 10px;
  border-color: #333;
  background-color: #333;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
}

.image-popup-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-popup-image {
  max-width: 80%;
  object-fit: contain;
}

.image-popup-copy-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.image-popup-copy-button:hover {
  background-color: #45a049;
}


@media (max-width: 600px) {
  .image-popup-container {
    max-width: 90%;
    max-height: 90%;
  }
  .image-popup-image {
    max-width: 180%;
    object-fit: contain;
  }
}