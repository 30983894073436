@font-face {
  font-family: 'Mabook';
  src: url('/public/Mabook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.main-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ff84ae;
}

.App-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tribute-logo {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 700px;
  height: auto;
}

.App-header {
  margin-bottom: 20px; /* Adjust this value to move the title towards the top */
}

.title {
  color: white;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000; /* Creates a black outline */
}

.button-container {
  position: absolute;
  top: 6vh;
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  justify-content: center; /* Center buttons horizontally */
  z-index: 3; /* Ensure buttons are above the background */
}

.nav-button {
  width: 148px;
  height: 148px;
  margin: 10px;
  transition: transform 0.2s ease;
}

.nav-button:hover {
  transform: scale(1.1);
}

.bottom-left-image {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1; /* Ensure it's above the background */
  width: 28vw; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-popup-container {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px;
}

.image-popup-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-popup-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.create-tribute-button {
  background-color: #731c96; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 24px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  margin: 20px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  animation: bounce 0.5s infinite alternate;
  font-family: 'Bungee Tint', cursive; /* Apply Bungee Tint font */ 
    padding: 8px 16px;
    position: fixed;
    bottom: 200px;
    left: 42vw;
    transform: translateX(-50%);
}

.entry-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ff84ae;
}

.enter-button {
  background-color: #731c96;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  margin: 20px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: transform 0.3s ease;
  font-family: 'Bungee Tint', cursive;
  animation: bounce 0.5s infinite alternate;
}

.enter-button:hover {
  background-color: #b07ff1;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}

.create-tribute-button:hover {
  background-color: #b07ff1; /* Darker green on hover */
}

.contract-address {
  font-family: 'Mabook', sans-serif;
  position: absolute;
  top: 85vh;
  font-size:x-large;
}
/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .bottom-left-image {
    width: 60vw;
    z-index: 15;
  }

  .button-container {
    top: 2vh;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contract-address {
    font-family: 'Mabook', sans-serif;
    position: absolute;
    top: 14vh;
    font-size:small;
  }

  .nav-button {
    width: 64px;
    height: 64px;
    margin: 5px;
    transition: transform 0.2s ease;
  }
  
  .nav-button:hover {
    transform: scale(1.1);
  }

  .tribute-logo {
    top: 25%;
    max-width: 80%;
  }

  .image-uploader-container {
    margin-top: 10vh;
  }

  .create-tribute-button {
    font-size: 18px;
    padding: 8px 16px;
    position: fixed;
    bottom: 34vh;
    left: 24vw;
    transform: translateX(-50%);
  }
}