.entry-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ff84ae;
  }
  
  .enter-button {
    background-color: #731c96;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    margin: 20px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.3s ease;
    font-family: 'Bungee Tint', cursive;
    animation: bounce 0.5s infinite alternate;
    position: absolute;
    bottom: 18%;
  }
  
  .enter-button:hover {
    background-color: #b07ff1;
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-10px);
    }
  }

  @media (max-width: 600px) {
    .enter-button {
      background-color: #731c96;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      margin: 20px 2px;
      cursor: pointer;
      border-radius: 12px;
      transition: transform 0.3s ease;
      font-family: 'Bungee Tint', cursive;
      animation: bounce 0.5s infinite alternate;
      position: absolute;
      bottom: 32%;
    }
    
  }